<template>
  <div id="app" >

    <title>
  Ayman TYM
      </title>
    <nav   v-show="checkComponent!='chart2' & checkComponent!='chart3' & checkComponent!='chart3sh'  "  class="navbar navbar-expand navbar-dark bg-dark">
      <a href class="navbar-brand" @click.prevent>TYM  {{this.$store.state.machineName}}</a>
      <div class="navbar-nav mr-auto">

         <li v-if="showAdminBoard || showOpBoard || showManagerBoard " class="nav-item">
          <router-link to="/ppd" class="nav-link"> QF</router-link>
          </li>

          <li v-if="showAdminBoard || showOpBoard || showManagerBoard" class="nav-item">
          <router-link to="/messages" class="nav-link">Operator </router-link>
          </li>
          <!-- <li v-if="showAdminBoard || showOpBoard || showManagerBoard" class="nav-item">
          <router-link to="/messages2" class="nav-link">Operator2 </router-link>
          </li> -->


          <li v-if="showAdminBoard || showMaintBoard || showManagerBoard" class="nav-item">
          <router-link to="/maintmessages" class="nav-link">Maintenance Failures</router-link>
         </li>
<li v-if="showAdminBoard  || showManagerBoard" class="nav-item">
          <router-link to="/batch" class="nav-link">Batches</router-link>
          </li>
<div class="dropdown">
  <button  v-if="showAdminBoard " class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Reports
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
   <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/managermessages" class="nav-link">Actual KPIs</router-link>
          </li>
          <!-- <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/managermessages-manual" class="nav-link">KPIs 2</router-link>
          </li> -->
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/managermessages-tot" class="nav-link">Monthly_KPI</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/stoppagespermonth" class="nav-link">Monthly_KPI2</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/stoppages_cat" class="nav-link">Stoppages</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/dayreport" class="nav-link">Daily_Report</router-link>
          </li>
         <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/managermessages-rates" class="nav-link">Rates</router-link>
          </li>


  </div>
</div>
<div class="dropdown">
  <button  v-if="showAdminBoard " class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Analysis
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">



          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/run_stop_chart" class="nav-link">Run/Stop Chart</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/run_stop_chartbyrunid" class="nav-link">Run/Stop Chart by Run</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/stoppages_chart" class="nav-link">Stoppages Chart</router-link>
          </li>


          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/p_inmonth" class="nav-link">Production Chart</router-link>
          </li>

          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/stoppages_most" class="nav-link">Peak Stoppages</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/chart3" class="nav-link">Display Chart</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/chart3monthly" class="nav-link">Monthly Chart</router-link>
          </li>

          <!-- <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/chart2" class="nav-link">Summary</router-link>
          </li> -->

  </div>
</div>
<p>-</p>

<div class="dropdown">
  <button  v-if="showAdminBoard " class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Configuration
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/messedit" class="nav-link">Normal_Edit</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/messedit2" class="nav-link">Anomalies_Edit</router-link>
          </li>
   <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/cats" class="nav-link">Category</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/subcats" class="nav-link">Sub Category</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
          <router-link style="color:black" to="/failures" class="nav-link">Failure</router-link>
          </li>
          <li v-if="showAdminBoard " class="nav-item">
              <router-link style="color:black" to="/product" class="nav-link">Products</router-link>
          </li>
            <li v-if="showAdminBoard " class="nav-item">
              <router-link style="color:black" to="/target" class="nav-link">Targets</router-link>
          </li>

  </div>
</div>



      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <font-awesome-icon icon="user-plus" />Sign Up
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" />Login
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.username }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" />LogOut
          </a>
        </li>
      </div>
    </nav>

    <div :class="{'containerClass':(checkComponent!='chart2' & checkComponent!='chart3'& checkComponent!='Stoppageschart' & checkComponent!='moststoppages' & checkComponent!='Runstopchart'& checkComponent!='Stoppagespermonth' &  checkComponent!='Runstopchartbyrunid'& checkComponent!='pinmonth' & checkComponent!='Stoppageschart'& checkComponent!='chart3sh') }" >

      <router-view />
    </div>

<!-- <div class="sidenav" v-show="checkComponent!='chart2'& checkComponent!='cats'& checkComponent!='profile'& checkComponent!='managermessages-tot'& checkComponent!='maintmessages' & checkComponent!='target'& checkComponent!='batch'& checkComponent!='product' & checkComponent!='Stoppageschart' & checkComponent!='managermessages-rates'& checkComponent!='messedit'& checkComponent!='Dayreport'& checkComponent!='Stoppages_cat'& checkComponent!='subcats'& checkComponent!='failures'& checkComponent!='chart3' & checkComponent!='Stoppageschart' & checkComponent!='moststoppages'  & checkComponent!='Stoppagespermonth' & checkComponent!='Runstopchart'&  checkComponent!='Runstopchartbyrunid'& checkComponent!='pinmonth' & checkComponent!='Stoppageschart'">
<div class="button-group">
    <button v-if="runmode==1" class="btn btn-success btn-block">On</button>
    <button v-if="runmode==0" class="btn btn-danger btn-block">Off</button>
</div>

<hr class="sidenav-divider">

<h6 class="fdesc-summary">Online Rate: <span class="text-info">{{(onlinerate) || 0}} unit/hr</span></h6>
<h6 class="fdesc-summary">BBL/hr: <span class="text-info">{{((onlineratebbl/159) || 0) | formatNumber}}</span></h6>
<h6 class="fdesc-summary">Current Mode: <span class="text-info">{{((onlineratebbl/onlinerate) || 0)}}</span> Litre</h6>

<div v-if="typeof mybatch[0] != 'undefined'">
    <h6 class="fdesc-summary">Run ID: <span class="text-info">{{(mybatch[0].runid) || 0}}</span></h6>
    <h6 class="fdesc-summary">Start Time: <span class="text-info">{{(mybatch[0].startdate || 0) | formatDate}}</span></h6>
    <h6 class="fdesc-summary">Product: <span class="text-info">{{(mybatch[0].productcode) || 0}}</span></h6>
</div>

<h6 class="fdesc-summary">Run Period: <span class="text-info">{{(period) | formatNumber}} hr</span></h6>
</div> -->

    </div>

</template>

<script>
import ReportService from "@/services/report.service";
import mqtt from "mqtt";
export default {
data:function(){
return{
  connection:null,
  myprod:'',
  message:'',
  mode:'',
  timer1:'',
  timer2:'',
  timer3:'',
  hourRte:'',
  onlinerate:'',
   lastratebbl:'',
  onlineratebbl:'',
  period:'',
  runmode:'',
  mybatch:[],
   mybatch2:{id:'',runid:'',startdate:'',enddate:'',dayend:'',sapid:'',productcode:'',empno:''},
}
  },

beforDestroyed(){
clearInterval(this.timer1);
clearInterval(this.timer2);
},
mounted (){
   setTimeout(this.swmode(),400);
  //setTimeout(this.getrates(),800);
   this.timer1 = setInterval(this.swmode, 15000)
   //this.timer2 = setInterval(this.getrates, 50000)
 },
 computed: {
  currentUser() {
    return this.$store.state.auth.user;
  },
  checkComponent() {
    return this.$route.name;
  },
  isFullScreenComponent() {
    return [
      'chart2', 'chart3sh','chart3', 'moststoppages', 'Runstopchart', 'Stoppagespermonth',
      'Runstopchartbyrunid', 'pinmonth', 'Stoppageschart','Runstopchart','Dayreport','cats',
    ].includes(this.checkComponent);
  },
  containerClass() {
    return this.isFullScreenComponent ? 'container2' : 'container';
  },
  showAdminBoard() {
    return this.currentUser?.roles?.includes('ROLE_ADMIN');
  },
  showOpBoard() {
    return this.currentUser?.roles?.includes('ROLE_OP');
  },
  showManagerBoard() {
    return this.currentUser?.roles?.includes('ROLE_MANAGER');
  },
  showMaintBoard() {
    return this.currentUser?.roles?.includes('ROLE_MAINT');
  }
},
  methods: {
    doSubscribe() {
var idd='74FE484C6F73';  //locaal
var idd3='74FE48473887';  //mobil
var idd2='74FE484C6EBC';  //net  to be changed to new ADAM 6050 for 5L machine
var idd4='74FE486031CA'  //new asher

      let self=this;

  var  client  = mqtt.connect('ws://192.168.1.8:9001');
   var topic=("Advantech/" + idd + "/data");
  client.on('connect',()=>{
    client.subscribe(topic);
    })
  client.on("message",function(topic,message){

    var d =JSON.parse(message).di8;
    self.myprod=d;
    //console.log('4l counter :   '+self.myprod);
  })
  },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

   swmode(){

  ReportService.getlastmode().then(result => {
     if ( result.data[0] !== undefined){
     this.mybatch = result.data;
     this.$store.state.sbatch=this.mybatch;
     }
           })
      .catch(error => {
        console.error(error);
      });


   // this.Getrunmode(this.$store.state.midDo4);
  ReportService.getlockmode(this.$store.state.midDo4).then(res => {
    this.runmode = res.data[0].lockmode
    console.log('lockmode-ay  ' +res.data[0].lockmode)
           })
      .catch(error => {
        console.error(error);
      });



   //

  ReportService.getlastmode().then(res => {
    if ( res.data[0]  !== undefined){
    this.mode = res.data[0].mode   ;
    let d1=new Date(res.data[0].startdate);
   let d2=  new Date();
   var diff = (Math.abs(d2 - d1))/60000/60;
   this.period=diff;
    }
      });
  },
chotime() {
     var today = new Date();
      var t1= new Date(this.currentmessage.insertdate);
      var sub=this.bsubcat;
      var diff = (Math.abs(today - t1))/60000;
      this.cholasttime=diff;
          if (sub=='CHO') {
      var t2=today.getHours()-t1.getHours();
         }
     return diff;
    },
  Getrunmode(mid){  //getlockmode
    ReportService.getlockmode(mid).then(res => {
    this.runmode = res.data[0].lockmode
           })
      .catch(error => {
        console.error(error);
      });
     },
////////////
getHourRate(){
ReportService.getHourRate().then(res => {
   this.hourRate = res.data[0].hourRate  ;

      });
},

   getrates(){


  ReportService.getrates().then(res => {
      if (res.data[0] ==undefined){
    this.onlinerate = 0;
    this.onlineratebbl=0;
    return;
   }
    this.onlinerate = res.data[0].onlinerate   ;
     this.lastratebbl = (res.data[0].lastratebbl)  ;
    this.onlineratebbl = (res.data[0].onlineratebbl) ;
      });
  },
letmode(){
let data={};
if (this.mode==4 ) {
  data.mode=5
}
  else {
    data.mode=4
  }
ReportService.letmode(data)
     .then(resp => {

       })
      .catch(error => {
        console.log(error);
      }).then(()=>{
    this.swmode();
      });

}

  },
}

</script>
<style scoped>

body {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  background-image:url("TYM-LOGO.png"); /* Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire page */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center center; /* Centers the image */
  height: 100vh; /* Ensures the background covers the full height */
  width: 100vw; /* Ensures the background covers the full width */
  overflow: hidden; /* Prevents unwanted scrollbars if content overflows */
}

.sidenav {
  height: 100%;
  width: 2%;
  position: fixed;
  z-index: 1;
  top: 100;
  left: 0;


  /* background-color:  rgb(4, 4, 94);  rgb(24, 15, 17);
  */
  background-color:  hwb(342 93% 3%);
color:#f7f4f0;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  /* color: #0044ff; */
  /* color: #00ff40; */
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.container {
  margin-left: 2%; /* Same as the width of the sidenav */
  font-size: 15px; /* Increased text to enable scrolling */
  padding:  10px;
color:#0a0a0e;
background-color: rgba(252, 244, 252, 0);
  float:left;
  width:100%;



}
.container2 {
  margin: 0;
  padding: 1rem;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
.mody{
  color: #00ff9d;
  text-indent: 5px;
}
/* General dropdown styling */
.dropdown {
  display: inline-block;
  margin-right: 10px;
}

/* Styling for the dropdown button */
.dropdown-toggle {
  padding: 5px 10px; /* Reduce padding to make it thinner */
  font-size: 0.9em; /* Slightly smaller font size */
  height: 30px; /* Adjust height if needed */
  background-color: #ffc107; /* Keeps the warning color but feel free to adjust */
  border: none; /* Remove borders for a cleaner look */
  border-radius: 4px; /* Slightly rounded corners for a modern look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Styling for the dropdown menu */
.dropdown-menu {
  min-width: 150px; /* Control the dropdown width */
  padding: 5px 0; /* Reduce padding inside dropdown */
  font-size: 0.9em; /* Smaller font for a more compact look */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Softer shadow */
  border-radius: 4px; /* Rounded corners */
}

/* Individual dropdown items */
.dropdown-menu .nav-item {
  padding: 5px 15px; /* Thinner padding for items */
}

/* Style for dropdown links */
.dropdown-menu .nav-link {
  color: #333; /* Darker color for readability */
  padding: 3px 0; /* Adjust link padding for a compact look */
  transition: color 0.2s;
}

.dropdown-menu .nav-link:hover {
  color: #ff0000; /* Change color on hover */
  text-decoration: none; /* No underline */
}
.sidenav {
    width: 12.5%; /* Reduce width to make the sidenav slimmer * /
    padding: 10px; /* Adjust padding to make the sidenav more compact */
    background-color: #1c2b3a; /* A light background color */
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for visual depth */
}

.sidenav .btn {
    width: 90%; /* Make buttons more compact */
    margin: 5px 0; /* Add some spacing between buttons */
    font-size: 14px; /* Smaller text for buttons */
}

.mody {
    font-size: 14px; /* Adjust the font size for smaller text */
    margin: 5px 0; /* Add spacing between elements */
}

h6 {
    font-size: 14px; /* Smaller text size for headings */
    margin-bottom: 8px; /* Add some spacing at the bottom of headings */
}

/*  */

</style>
